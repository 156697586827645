export class PopUp {
    constructor(options = {}) {
        this.pubsub = options.pubsub || {};
        this.data = options.data || {};
        this.storeName = options.storeName;
        this.Alpine = options.Alpine;
    }

    postComponent() {
        const {Alpine} = this;
        const {height, width} = this.getDimensions(this.data['asset_orientation'].toLowerCase());
        const {
            asset_type,
            asset_orientation,
            experiment_id,
            nonce,
            marketing_materials
        } = this.data;
        let showCount = 0;

        // Save a reference to the current context of postComponent
        const self = this;

        const pubsub = this.pubsub;
        const {chosenKey, chosenMaterial, targetUrl} = selectRandomEntry(marketing_materials);
        // console.log('chosenKey', chosenKey, "chosenMaterial", chosenMaterial);

        let isClicked = false; // used in preventing double counting of clicks on asset

        // Prepare and return the component data
        const rVal = {
            ...this.data,
            postIds: this.data.marketing_materials || [],
            content: chosenMaterial['post_content'],
            targetUrl,
            closeLabel: 'hide',
            showCount,
            isMobile: () => window && window.innerWidth < 768,
            hide: function() {
                pubsub.publish('hidden', this.getExperimentData());
                Alpine.store('visibilityStore').isVisible = false
            },
            getExperimentData: function () {
                return {
                    experiment_id,
                    viewedAt: new Date().toISOString(),
                    type: asset_type,
                    orientation: asset_orientation,
                    experiment_variable: chosenKey,
                    nonce
                };
            },
            init: function(){
                    Alpine.effect(() => {
                        if (Alpine.store(self.storeName).isVisible) {
                            this.trackView();
                        }

                        if (Alpine.store(self.storeName).isVisible) {
                            document.addEventListener('click', (event) => this.outsideClickListener(event, this));
                        } else {
                            document.removeEventListener('click', (event) => this.outsideClickListener(event, this));
                        }
                    });
            },
            trackView: function() {
                showCount += 1;
                console.log("TRACK VIEW IS CALLED, SETTING UP OBSERVER BUT NOT TRACKING A VIEW", this.$el);
                const observer = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            pubsub.publish('viewed', this.getExperimentData());
                            observer.unobserve(entry.target);
                        }
                    });
                }, {threshold: 0.1});

                observer.observe(this.$el);
            },
            trackClick: function (e) {
                if( isClicked ){ return; } // prevent recording a double click as two clicks.
                isClicked = true;
                pubsub.publish('clicked', this.getExperimentData());
            },
            get styleObject() {
                return {
                    width: `${width}px`,
                    height: `${height}px`,
                }
            },
            outsideClickListener: (event, ref) =>{
                if (!ref.$el.contains(event.target)) {
                    ref.hide();
                }
            },
            get classObject() {
                return {
                    popup: asset_type === 'Popup',
                    slidein: asset_type === 'Popup', // change to slidein
                };
            },
        };
        return rVal;
    }

    getDimensions(asset_orientation) {
        const maxLength = 800;
        const minLength = 400;
        const squareLength = 562;
        const squareWidth = 600;

        if (asset_orientation === 'portrait') {
            return {
                height: maxLength,
                width: minLength,
            }
        }
        return asset_orientation === 'landscape' ? {
            height: minLength,
            width: maxLength
        } : {
            height: squareLength,
            width: squareWidth,
        }
    }
}

function selectRandomEntry(dataset) {
    // Ensure the dataset is not empty
    if (!Array.isArray(dataset) || dataset.length === 0) {
        throw new Error("Dataset is empty or not an array");
    }

    // Randomly select one entry
    const randomIndex = Math.floor(Math.random() * dataset.length);
    const selectedEntry = dataset[randomIndex];

    // Return the chosen material and its key
    return {
        chosenKey: selectedEntry.test_case_name,
        chosenMaterial: selectedEntry.marketing_material,
        targetUrl: selectedEntry.acf.target_url,
    };
}