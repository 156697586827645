export default class PubSub {
    constructor() {
        this.subscribers = {};
    }

    subscribe(event, callback) {
        if (!this.subscribers[event]) {
            this.subscribers[event] = [];
        }
        this.subscribers[event].push(callback);
        const index = this.subscribers[event].length - 1;

        // return unsubscribe function
        return () => {
            this.subscribers[event].splice(index, 1);
        }
    }

    publish(event, data) {
        if (!this.subscribers[event]) return;
        this.subscribers[event].forEach(callback => {
            callback({event_name: event, ...data});
        })
    }
}