import PubSub from "./lib/PubSub";
import {PopUp} from "./lib/PopUp";
import Alpine from "alpinejs";
import {ConversionTracking} from "./lib/conversionTracking";

const storeName = 'visibilityStore';

function startAlpine() {
    window.Alpine = Alpine
    const pubsub = new PubSub();
    const conversonTracker = new ConversionTracking({pubsub})
    window.popup = new PopUp({
        Alpine,
        pubsub,
        storeName,
        data: acfData || {},
    });
    Alpine.start()
}

document.addEventListener('alpine:init', () => {
    Alpine.store(storeName, {
        isVisible: false
    });

    // Check if the store exists; if not, initialize it
    if (Alpine.store(storeName) === undefined) {
        Alpine.store(storeName, {
            isVisible: false,
            isShowable: false
        });
    }

    // Function to fetch country code from WordPress endpoint
    async function fetchCountryCode() {
        try {
            const response = await fetch(`${window.location.origin}/wp-json/custom/v1/user-country`);
            if (!response.ok) {
                throw new Error('Failed to fetch country code');
            }
            const data = await response.json();
            console.log('RESPONS', data);
            return data.country_code; // Assuming the response is { "country_code": "US" }
        } catch (error) {
            console.error('Error fetching country code:', error);
            return null;
        }
    }

    // Fetch country code and set isShowable
    fetchCountryCode().then(countryCode => {
        console.log('COUNTRY CODE', countryCode);
        if (countryCode === 'US') {
            Alpine.store(storeName).isShowable = true; // Set isShowable to true for US
        } else {
            Alpine.store(storeName).isShowable = false; // Set isShowable to false for non-US
        }
    });

    console.log("ALPINE STORE", Alpine.store);

    // Set initial visibility
    Alpine.store(storeName).isVisible = acfData.asset_type !== 'Popup';

    // Change visibility after 3 seconds
    setTimeout(() => {
        Alpine.store(storeName).isVisible = true;
    }, 3000);


});
startAlpine();


